<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Editar inventario
            </div>

            <v-expansion-panels v-model="activePanels">
                <v-expansion-panel style="background-color: #F5F5F5" @click.prevent="toogleFilters">
                    <v-expansion-panel-header>
                        <span class="header">Filtros</span>
                        <template v-slot:actions>
                            <v-btn icon @click.stop="toogleFilters">
                                <v-icon>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                                <v-autocomplete
                                    class="mb-n7"
                                    cache-items dense hide-selected outlined
                                    v-model="filtros.Art"
                                    :items="artList"
                                    open-on-clear
                                    item-value="ArtId"
                                    item-text="ArtNom"
                                    no-data-text="Introduzca texto a buscar"
                                    :label="'Artículo'"
                                    return-object
                                    clearable
                                    :menu-props="{'open-on-click': true}"
                                    @change="searchTrigger === '0' ? '1':'0'"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                                <v-text-field
                                    class="mb-n7"
                                    outlined dense required text
                                    v-model="filtros.ArtCod"
                                    label="Código"
                                    @change="searchTrigger === '0' ? '1':'0'"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                              <ArtCatFind 
                                  class="mb-n7" 
                                  ref="artCatFind" 
                                  v-model="filtros.ArtCatParent" 
                                  label="Familia"
                                  :showParentsOnly="true"
                                  @change="filtros.ArtCat = null">
                              </ArtCatFind>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                              <ArtCatFind 
                                  class="mb-n7" 
                                  ref="artCatFindSub" 
                                  v-model="filtros.ArtCat" 
                                  label="Subfamilia"
                                  :disabled="filtros.ArtCatParent == null"
                                  :artCatIdParent="filtros.ArtCatParent != null ? filtros.ArtCatParent.ArtCatId : null"
                                  @change="searchTrigger === '0' ? '1':'0'">
                              </ArtCatFind>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                              <v-checkbox 
                                  v-model="filtros.Pendiente"
                                  label="Mostrar sólo pendientes"
                                  hide-details
                                  @change="searchTrigger === '0' ? '1':'0'">
                              </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{ itemsPerPageOptions: [15, 30, 50, 100], disablePagination:loading, disableItemsPerPage: loading }"
                :loading="loading"
                :search="searchTrigger"
                :custom-filter="tableCustomFilter"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 mt-3"
                dense
                >
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Existencia="{ item }">
                    <template v-if="item.new !== true">{{ item.Existencia }}</template>
                    <template v-else>
                      <div style="display: flex; align-items: center;">
                        <v-btn
                          @click="onBtnCapturarPeso2(item)"
                           color="primary"
                           class="mt-2">
                           PESO
                        </v-btn>
                        <v-text-field
                            class="my-1" reverse
                            hide-details outlined dense required text
                            type="number"
                            v-model="item.Existencia"
                            label="Existencia"
                        ></v-text-field>
                        </div>
                    </template>
                </template>
            </v-data-table>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-footer app bottom fixed padless>
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                    >
                      Aceptar
                    </v-btn>
                    
                    <v-btn
                        @click="goToList"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                    >
                      Cancelar
                    </v-btn>
                </v-row>
            </v-footer>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import funcs from "../funcs";
import ArtCatFind from "../components/ArtCatFind.vue";
export default ({
    computed: {
        ...mapState(['empId', 'perId', 'perIdAux', 'urlRaiz', 'urlRaizTpv', 'esTienda', 'esEmpleado'])
    },
    components: {
        ArtCatFind
    },
    data () {
        return {
            activePanels: 0,
            loading: true,
            zonaId: null,
            isTpv: false,
            inventario: {},
            dataRows: [],
            searchTrigger: '0',
            options: {itemsPerPage: 15},
            headers: [
              { text: 'Articulo', width:300, sortable: false, value: 'Art.ArtNom' },
              { text: 'Código', width: 75, sortable: false, value: 'Art.ArtCod' },
              { text: 'Existencia', width:75, sortable: false, align: 'end', value: 'Existencia' }
            ],
            filtros: {
                // Dir: null,
                PerId: null,
                Art: null,
                ArtCod: '',
                ArtCat: null,
                ArtCatParent: null,
                Pendiente: false
            },
            artList: [],
            focusedElement: null,
        }
    },
    // watch: {
    // },
    mounted() {
        this.inventario.InventarioId = this.$route.params.id;
        this.zonaId = this.$route.params.zona;
        this.isTpv = this.$route.params.isTpv === '1';

        document.addEventListener('focusin', this.setFocusedElement);
        // if (this.esTienda) {
        //     this.filtros.DirId = this.$store.state.dir.DirId;
        // }

        // Per init.
        if (this.esEmpleado)
        {
            this.filtros.PerId = this.perId;
        }
        else if(this.perIdAux != null && this.perIdAux != 0)
        {
            this.filtros.PerId = this.perIdAux;
        }

        this.loadData();
    },
    beforeDestroy() {
      // Limpiar el evento cuando el componente sea destruido
      document.removeEventListener('focusin', this.setFocusedElement);
    },
    methods: {
      goToList() {
        this.$router.push('/inventarios');
      },
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            InventarioId: this.inventario.InventarioId,
            DirId: this.filtros.DirId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inventario/get", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Inventario;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar el inventario");
                this.goToList();
            }
          });
        })
      },
      getArtListFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'ALL_ARTS',
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/art/", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  items
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                this.goToList();
                alert("Error al cargar los articulos");
            }
          });
        })
      },
      postNewDataToApi() {
        this.loading = true;

        const data = this.dataRows.filter(f => f.new && this.validateExistencia(f.Existencia) !== 0).map((m) => {
            return {
                ArtId: m.Art.ArtId,
                Existencia: m.Existencia
            }
        });

        return new Promise((resolve) => {
          const controllerParameters = {
              InventarioId: this.inventario.InventarioId,
              PerId: this.filtros.PerId,
              ZonaId: this.zonaId,
              Arts: data
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inventario/save", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            this.loading = false;
            if(error != null) {
                alert("Error al guardar el inventario");
            }
          });
        })
      },
      capturarPesoFromTpv() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {};
          const AuthToken = localStorage.getItem('token');
          axios({ method: "GET", "url": this.urlRaizTpv, "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;
            setTimeout(() => {
                this.loading = false;
                resolve({
                  item: { peso: item.peso }
                })
            }, 1000)
          })
          .catch(error => {
            this.loading = false;
            if(error != null) {
                alert("Error al capturar el peso");
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert("Inventario no encontrado");
              this.goToList();
              return;
            }

            this.inventario = data.item;
            this.dataRows = data.item.InventarioArt

            this.getArtListFromApi()
            .then(data => {
              if(data.items == null) {
                alert("Error al cargar los articulos");
                this.goToList();
                return;
              }

              this.artList = data.items;
              this.calcularDatarows();
            });
          });
      },
      calcularDatarows() {
          let dataRows = this.inventario.InventarioArt;
          const artListMapped = this.artList.map((m) => { return {
            Art: m,
            Existencia: 0,
            new: true,
            pendiente: !this.inventario.InventarioArt.some(s => s.Existencia !== 0 && s.ArtId === m.ArtId)
          }
          })
          dataRows = dataRows.concat(artListMapped);
          this.dataRows = dataRows;
      },
      tableCustomFilter(value, search, item) {
        const artFilter = (this.filtros.Art == null) || (this.filtros.Art.ArtId === item.Art.ArtId);
        const artCodFilter = (this.filtros.ArtCod === '') || (this.filtros.ArtCod === item.Art.ArtCod);
        const artCatFilter = (this.filtros.ArtCat == null) || (item.Art.ArtCatArt != null && (item.Art.ArtCatArt.some(s => s.ArtCatId === this.filtros.ArtCat.ArtCatId)));
        const pendienteFilter = !this.filtros.Pendiente || item.pendiente;
        return artFilter && artCodFilter && artCatFilter && pendienteFilter;
      },
      onBtnSave() {
          if (this.loading) return;
          this.postNewDataToApi().then(data => {
              if (data.item.Success) {
                  this.goToList();
              }
              else {
                  alert("Error al guardar el inventario.");
              }
          });
      },
      setFocusedElement(event) {
        this.focusedElement = event.target;
      },
      onBtnCapturarPeso(item) {
        if (this.loading) return;
        this.capturarPesoFromTpv().then((data) => {
        item.Existencia = data.item.peso;
      });
      },
      async onBtnCapturarPeso2(item) {
        try {
          this.loading = true;
          // Llamada real a axios
          const response = await axios.get('http://localhost:5000/capturar_peso');
          item.Existencia = response.data.peso; // Ajusta esto según la estructura de tu respuesta
        } catch (error) {
          console.error('Error al capturar peso:', error);
        } finally {
          this.loading = false;
        }
      },
      toogleFilters() {
        this.activePanels = this.activePanels == null ? 0 : undefined;
      },
      validateExistencia(value) {
          try
          {
              let valueString = new String(value);
              valueString = valueString.replace(',','.');
              const valueNumber = parseFloat(valueString);
              if (isNaN(valueNumber)) return 0;
              return valueNumber;
          }
          catch
          {
              return 0;
          }
      },
      formatDate(value) {
          return funcs.formatDate(value, true);
      }
    } 
})
</script>
